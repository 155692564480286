import ToolLayout from "@layouts/ToolLayout";
import { getWindow } from "@utils/window";
import React, { DependencyList, useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { Helmet } from "react-helmet";

const IS_DEBUG = process.env.NODE_ENV === "development";
// const IS_DEBUG = false;

const DEBUG_DELAY = 5000;

const BUTTON_VISIBLE_DELAY = 2000;

/**
 * アプリから、宿泊予約のValueCommerceアフィリエイトリンク先に自動で飛ばすページです。
 * ValueCommarceのJSがオリジナルのURLをアフィ用に変換するので、変換されたらリダイレクトします。
 *
 * ローカルでの確認用URL:
 * 一休：
 * http://localhost:8000/owner/jump?url=https%3A%2F%2Fwww.ikyu.com%2F00002629%2F&label=%E4%B8%80%E4%BC%91%E3%81%A7%E4%BA%88%E7%B4%84%E3%81%99%E3%82%8B
 * じゃらん：
 * http://localhost:8000/owner/jump?url=https%3A%2F%2Fwww.jalan.net%2Fyad322856%2F&label=%E3%81%98%E3%82%83%E3%82%89%E3%82%93%E3%81%A7%E4%BA%88%E7%B4%84%E3%81%99%E3%82%8B
 */
const AdvanceCheckInPage = () => {
  const window = getWindow((w) => w);
  const { url, label } = useQueryParams();
  const ELEMENT_ID = "link-target";
  const [visibleButton, setVisibleButton] = useState(false);
  useEffect(() => {
    const timer = setTimeout(
      () => setVisibleButton(true),
      BUTTON_VISIBLE_DELAY
    );
    return () => clearTimeout(timer);
  }, []);
  useWatchHrefChange(
    ELEMENT_ID,
    (url: string) => {
      if (!window) return console.error("window is undefined");
      const redirect = () => {
        window.location = url;
      };
      if (IS_DEBUG) setTimeout(redirect, DEBUG_DELAY);
      else redirect();
    },
    []
  );

  return (
    <ToolLayout noIndexNoFollow noFooter>
      <Container fluid style={{ maxWidth: 400, textAlign: "center" }}>
        <div>ページを移動します...</div>
        <br />
        <div style={{ opacity: visibleButton ? 1 : 0 }}>
          {url && (
            <a href={url} id={ELEMENT_ID}>
              <Button>{label}</Button>
            </a>
          )}
          <br />
          <small>
            ※自動で移動しない場合は
            <br />
            上記のボタンを押してください。
          </small>
        </div>
      </Container>
      <ValueCommerceScript />
    </ToolLayout>
  );
};
export default AdvanceCheckInPage;

const VALUE_COMMERCE_PID = "890156065";

const ValueCommerceScript = () => (
  <Helmet>
    <script>{`var vc_pid = '${VALUE_COMMERCE_PID}';`}</script>
    <script
      type="text/javascript"
      src="https://aml.valuecommerce.com/vcdal.js"
      async
    ></script>
  </Helmet>
);

const useWatchHrefChange = (
  elementId: string,
  onUrlChange: (url: string) => void,
  deps: DependencyList = []
) => {
  // MutationObserverのコンフィグを設定する
  const config = {
    attributes: true, // 属性の変更を監視
    attributeFilter: ["href"], // 'href'属性の変更のみを監視
  };

  // MutationObserverのコールバック関数を定義する
  const callback: MutationCallback = function (mutationsList) {
    if (!mutationsList) return;
    for (const mutation of mutationsList) {
      if (mutation.type === "attributes" && mutation.attributeName === "href") {
        const target = mutation.target as any;
        const newUrl = target.getAttribute("href");
        onUrlChange(newUrl);
      }
    }
  };

  useEffect(() => {
    // MutationObserverをインスタンス化し、監視を開始する
    const observer = new MutationObserver(callback);

    // 監視対象の要素を選択する
    const target = document.getElementById(elementId);
    if (!target) throw new Error("target not found");
    observer.observe(target, config);
  }, [elementId, onUrlChange, ...deps]);
};

const useQueryParams = () => {
  const locationSearch = getWindow((w) => w.location.search);
  const defaults = React.useMemo(() => {
    const params = new URLSearchParams(locationSearch || "");
    // See: wanpass-backend: src/entry_server.ts _getAdvanceFormUrl
    const url = params.get("url");
    const labelParam = params.get("label");
    const label = labelParam || (url ? "移動する" : null);

    return { url, label };
  }, [locationSearch]);
  return defaults;
};
